import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import Sarah from '../../images/directors4.jpg'
import BG from '../../images/awards-bg.jpg'

class SarahCox extends React.Component {
	render() {
		const siteTitle = 'Sarah Cox'
		const siteDescription = this.props.data.site.siteMetadata.siteDescription

		return (
			<Layout title={siteTitle} location={this.props.location}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div class="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Sarah Cox</h3>
								<div className="row">
									<div className="col-lg-6 col-md-12">
										<p>
											Sarah Cox is the National Administration Director who's
											role is to deliver professional and efficient management
											of all the offices nationally. Sarah also oversees the
											marketing and events activities of the Group.
										</p>
										<p>
											Since beginning her public practice career in 2002, Sarah
											has developed a keen understanding of the importance of
											ensuring efficient internal operations in order to provide
											a high level of service to our clients. She continually
											works with our branches to develop and improve our support
											processes.
										</p>
									</div>
									<div className="col-lg-6 col-md-12">
										<img src={Sarah} className="img-fluid" alt="Sarah Cox" />
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<p>
											Sarah assisted with the set up of our QLD operations in
											April 2008 before transferring to our VIC branch in May
											2009. Sarah returned to the QLD branch in April 2013 and
											continues to work closely with each of our offices on a
											day-to-day basis.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default SarahCox

export const SarahCoxPageQuery = graphql`
	query SarahCoxPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
